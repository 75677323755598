import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  Step, StepContent, StepLabel, Stepper, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'creative-components/CustomButtons/Button';

import { DASHBOARD_ROUTE } from 'components/AuthDataProvider/AuthDataProvider';
import { useDataFilterContext } from 'components/DataMap/DataFilterProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import SimpleCreateCampaignStep from 'components/SimpleLaunches/SimpleCampaign/SimpleCreateCampaignStep';
import { SimplePricingPlan } from 'components/SimpleLaunches/SimpleCampaign/SimplePricingCards';
import SimpleCreateAccountStep from 'components/SimpleLaunches/SimpleCreateAccountStep';

import analyticsClient from 'utils/analytics';
import { createSimpleCampaign } from 'utils/api';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto',
    padding: '24px 30px 12px',
    position: 'relative',

    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },

    '@media (min-width: 600px)': {
      width: '580px', // 12 34px-width columns with 14px gutters
    },
    '@media (min-width: 900px)': {
      width: '840px', // 12 46px-width columns with 24px gutters
    },
    '@media (min-width: 1200px)': {
      width: '1104px', // 12 60px-width columns with 32px gutters
    },
    '@media (min-width: 1440px)': {
      width: '1344px', // 12 80px-width columns with 32px gutters (including on ends, so 12 * 80 + 12 * 32)
    },
  },
  stepLabel: {
    fontSize: '18px',
  },
  stepDescription: {
    marginBottom: '20px',
  },
  stepContainer: {

  },
  map: {
    minHeight: '95vh',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 20px',
  },
  goToDashboardButton: {
    marginTop: '20px',
    float: 'right',
  },
}));

const steps: { [key: string]: string } = {
  'Choose farm': '',
  'Create account': 'Review details required for your Harvist account',
  'Campaign Created': 'Your campaign has been created and will start shortly! Give us some time to crunch the data and prepare everything for you.',
};

const SimpleCreateCampaignPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    availableEventTypes, selectedEventType, setSelectedEventTypeByName,
  } = useDataFilterContext() as any;

  const [activeStep, setActiveStep] = React.useState(0);

  const [selectedPlan, setSelectedPlan] = React.useState<SimplePricingPlan | undefined>();

  const [zipCode, setZipCode] = React.useState<string | undefined>();
  const [apnsTotal, setApnsTotal] = React.useState<number | undefined>();
  const [apnsWithPropensityToSell, setApnsWithPropensityToSell] = React.useState<number | undefined>();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  React.useEffect(() => {
    if (!availableEventTypes) return;

    // Always full farm
    setSelectedEventTypeByName('Full Farm');
    const analyticsCampaignEventType = 'Full Farm';

    // Track GTM event
    analyticsClient.track('simple_campaign_creation_started', {
      campaign_event_type: analyticsCampaignEventType,
    });
  }, [availableEventTypes]);

  if (!selectedEventType) return (<LoadingIndicator modal />);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {Object.keys(steps).map((label) => (
          <Step key={label}>
            <StepLabel classes={{ label: classes.stepLabel }}>{label}</StepLabel>
            <StepContent>
              <Typography className={classes.stepDescription}>{steps[label]}</Typography>
              <div className={classes.stepContainer}>
                {activeStep === 0 && (
                  <SimpleCreateCampaignStep
                    handleNext={handleNext}
                    setZipCode={setZipCode}
                    apnsTotal={apnsTotal}
                    setApnsTotal={setApnsTotal}
                    apnsWithPropensityToSell={apnsWithPropensityToSell}
                    setApnsWithPropensityToSell={setApnsWithPropensityToSell}
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
                  />
                )}

                {activeStep === 1 && (
                  <SimpleCreateAccountStep
                    handleNext={handleNext}
                    onLaunch={async () => {
                      await createSimpleCampaign(zipCode!, selectedPlan!.numApns);
                    }}
                  />
                )}

                {activeStep === 2 && (
                  <Button
                    round
                    color="primary"
                    className={classes.goToDashboardButton}
                    onClick={() => history.push(DASHBOARD_ROUTE)}
                  >
                    Go to My Dashboard
                  </Button>
                )}
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default SimpleCreateCampaignPage;
